.home-page-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #EFEFEF;
  text-align: center;
  padding: 20px;
  flex-direction: column;
}

.content {
  max-width: 600px;
}

.content h2 {
  color: #133B5C;
  margin: 0;
  padding-bottom: 10px;
}

.home-page-produtos {
  flex: 1;
  display: flex;
  justify-content: center;
  column-gap: 50px;
}

.home-page-gas {
  width: 35%;
  background-color: #133B5C;
  color: #EFEFEF;
  padding: 20px;
  border-radius: 10px;
}

.home-page-financeiro {
  width: 35%;
  background-color: #133B5C;
  color: #EFEFEF;
  padding: 20px;
  border-radius: 10px;
  height: fit-content;
}

.home-page-button {
  background-color: #29BB89;
  color: #EFEFEF;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .home-page-produtos {
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
  }

  .home-page-gas {
    width: 80%;
  }

  .home-page-financeiro {
    width: 80%;
  }
}