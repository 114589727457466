.ifraud-container {
  background-color: #EFEFEF;
}

.video-section {
  width: 100%;
  background-color: #0F2C4D;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-video {
  position: absolute;
  margin-top: 80px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;

  @media (max-width: 768px) {
    margin-top: 60px;
  }
}

.ifraud-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
}

.ifraud-subtitle {
  margin-top: 1rem;
  font-size: 0.9rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  color: #133B5C;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }

  animation: fadeInUp 1s ease-out 0.3s forwards;
  opacity: 0;
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 45vh 25px 50px;

  @media (max-width: 768px) {
    padding: 100vh 25px 50px;
  }
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-cards-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  background-color: #fff;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.card-content {
  padding: 1.5rem;
}

.card.hoverable:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.card-content h3 {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #133B5C;
}

.why-choose-us {
  margin-top: 5rem;
  text-align: center;
}

.why-choose-us h2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #133B5C;
}

.why-choose-us p {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 2rem;
}

.grid-cards-2 {
  display: grid;
  gap: 1.5rem;
  text-align: left;
}

ul {
  margin-top: 1rem;
  padding-left: 1.5rem;
  list-style-type: disc;
}

li {
  font-size: 0.9rem;
}

.form-container {
  margin-top: 5rem;
  text-align: center;
}

.form-container h2 {
  color: #133B5C;
}

.form-box {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-box input[type='text'],
.form-box input[type='email'] {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  outline: none;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  font-size: 0.8rem;
  color: #555;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

.form-box button {
  width: 100%;
  padding: 0.75rem;
  background-color: #efefef;
  color: #133b5c;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
}

.form-box button:hover {
  background-color: #dcdcdc;
}

.check-list li {
  list-style: none;
  position: relative;
  padding-left: 1.5rem;
}

.check-list li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: green;
}

.report-list li {
  list-style: none;
  position: relative;
  padding-left: 1.5rem;
  font-size: 0.9rem;
}

.report-list li::before {
  content: '●';
  position: absolute;
  left: 0;
  font-size: 1.5rem;
  line-height: 0.5;
}

.report-list li:nth-child(1)::before {
  color: #4caf50;
}

.report-list li:nth-child(2)::before {
  color: #808080;
}

.report-list li:nth-child(3)::before {
  color: #FF0000;
}

.report-list li:nth-child(4)::before {
  color: #862386;
}

.report-list li:nth-child(5)::before {
  color: #FFFF00;
}

.report-list li:nth-child(6)::before {
  color: #D3D3D3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  } to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 768px) {
  /* .section-container {
    padding-top: 700px;
  } */

  .video-section {
    height: 500px;
  }
}
