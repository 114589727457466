.footer {
  background-color: #133B5C;
  color: #EFEFEF;
  padding: 20px 20px 10px;
  display: flex;
  flex-direction: column;
}

.footer-div {
  display: flex;
  flex-direction: row;
}

.footer-left {
  text-align: left;
  width: 50%;
  align-content: flex-end;
}

.footer-right {
  text-align: right;
  width: 50%;
}

.footer p {
  margin: 0;
  font-size: small;
  padding-bottom: 1px;
}

.footer a {
  color: #EFEFEF;
  font-size: small;
}

.social-icons {
  margin-bottom: 10px;
  width: 100%;
  align-content: center;
  text-align: left;
}

.social-icons a {
  color: #EFEFEF;
  margin: 0 10px;
  font-size: 24px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #ccc;
}

@media (max-width: 768px) {
  .footer-div {
    flex-direction: column;
  }

  .footer-left {
    width: 100%;
  }

  .footer-right {
    width: 100%;
    text-align: left;
    padding-top: 10px;
  }
}